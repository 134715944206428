import { inject } from "vue";

export function postMessage(message: object) {
    const windowArea = (window as any).ReactNativeWebView;
    if (windowArea) {
        windowArea.postMessage(JSON.stringify(message));
    } else {
        window.postMessage(JSON.stringify(message));
    }
}

export function AppOrWorker(worker:Worker)  {
    //if ((window as any).ReactNativeWebView != undefined) return new AppApi();
    return new WorkerApi(worker);
}

interface IAppOrWorker {
    postMessage(message: object): void;
    isApp(): boolean
}

export class AppApi implements IAppOrWorker {
    public postMessage(message: object) {
        const windowArea = (window as any).ReactNativeWebView;
        if (windowArea) {
            windowArea.postMessage(JSON.stringify(message));
        } else {
            window.postMessage(JSON.stringify(message));
        }
    }
    isApp(): boolean {
        return true;
    }
}

export class WorkerApi implements IAppOrWorker {
    constructor(w:Worker) {
        this.worker = w;
        console.log("WorkerApi", w);
    }
    worker: Worker 
    postMessage(message: object) {
        this.worker.postMessage(message);
    }
    isApp(): boolean {
        return false;
    }
}