<script lang="ts" setup>
    import { ref, onActivated, onBeforeMount } from 'vue';
    import {VueScrollPicker} from 'vue-scroll-picker';
    
    const props = defineProps<{
        modelValue: number | string | boolean | undefined | 'Yes' | 'No',
        min: number | undefined,
        max: number | undefined,
        options: string[] | string | undefined
    }>();
    const emit = defineEmits(["update", "next"]);
    const splitTemperature = (temperatureValue: string | undefined) => {
        if (!temperatureValue) return;
        if (temperatureValue.includes('.')) {
            const temperatureValueSplit = temperatureValue.split('.')
            temperature.value = parseInt(temperatureValueSplit[0])
            temperature2.value = parseInt(temperatureValueSplit[1])
            return;
        }
        temperature.value = parseInt(temperatureValue)
        temperature2.value = 0
    }
    const joinTemperature = (): string => temperature.value + '.' + temperature2.value;
    const temperatureOptions: any[] =  [...Array(220 + 1)].map((_, i) => { return {name: (-105 + i).toString(), value: -105+i}}).reverse();
    const decimalTemperatureOptions: any[] = [...Array(9 + 1)].map((_, i) => { return {name: (0 + i).toString(), value: 0+i}}).reverse();
    const temperature = ref<number>(Number(props.modelValue) || 0);
    const temperature2 = ref<number>(0);
    const wasSkipped = ref(false);
    const validateValue = (value: string): 'under' | 'over' | 'invalid' | 'ok' | 'ignore' => {
        if (value == undefined) return "invalid";
        const val = parseFloat(value)
        if (props.min != undefined && val < props.min) return "under";
        if (props.max != undefined && val > props.max) return "over";
        return "ok";
    }
    const onValueChange = () => {
        emit('update')
    }
    const load = (value: string | number | boolean | undefined) => {
        if (value != undefined) {
            if (value == "Skipped") {
                wasSkipped.value = true;
            } else {
                splitTemperature(props.modelValue?.toString())
            }
        } else {
            temperature.value = 0;
            temperature2.value = 0;
        }
    }
    const validate = () :  'under' | 'over' | 'invalid' | 'ok' | 'ignore' => { 
        if (wasSkipped.value) return "ignore";       
        return validateValue(joinTemperature())
    }
    const value = () => {
        if (wasSkipped.value) return "Skipped";
        return joinTemperature();
    }
    const skip = () => {
        wasSkipped.value = true;
    }
    defineExpose({ validate, load, value, skip });
</script>

<template>
    <div class="temp-check">
        <div style="width: 40%">
            <VueScrollPicker
                v-model="temperature"
                :options="temperatureOptions"
                v-on:update:model-value="() => onValueChange()"
            />
        </div>
        <span style="font-size: 4.5em; width: 2%; text-align: center; margin-top: -25px"> . </span>
        <div style="width: 40%">
            <VueScrollPicker 
                v-model="temperature2" 
                :options="decimalTemperatureOptions" 
                v-on:update:model-value="() => onValueChange()" 
            />
        </div>
    </div>
</template>

<style>
    .vue-scroll-picker {
        height: 25vh;
    }
    .vue-scroll-picker .vue-scroll-picker-item {
        margin: 16px;
        font-weight: bold;
    }

    .vue-scroll-picker .vue-scroll-picker-layer-top {
        border: unset !important;
        height: 120px;
        top: -20px;
        background: linear-gradient(180deg, rgba(255, 255, 255, 1) 10%, rgba(255, 255, 255, 0.21256424933254547) 77%, rgba(255, 255, 255, 0));
    }
    

    .vue-scroll-picker .vue-scroll-picker-layer-bottom {
        border: unset !important;
        border: unset !important;
        height: 120px;
        bottom: -30px;
        background: linear-gradient(0deg, rgba(255, 255, 255, 1) 10%, rgba(255, 255, 255, 0.21256424933254547) 77%, rgba(255, 255, 255, 0));
    }

    .vue-scroll-picker .vue-scroll-picker-layer-selection {
        width: 100%px;
        border-top: 2px solid green;
        border-bottom: 2px solid green;
        margin: 0 auto;
        height: 50px;
    }

    .vue-scroll-picker-rotator {
        font-size: 2em;
    }

    .temp-check{
        display: flex;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: center;
        align-items: center;
        /*height: 20rem;*/
    }
</style>