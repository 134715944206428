<script lang="ts" setup>
    import { ref, defineProps, defineEmits, defineExpose } from 'vue';
    const props = defineProps<{
        modelValue: number | string | boolean | undefined | 'Yes' | 'No',
        min: number | undefined,
        max: number | undefined,
        options: string[] | string | undefined
    }>();
    const error = ref<boolean>(false);
    const checkbox = ref<'Yes' | 'No'>(props.modelValue?.toString() as 'Yes' | 'No' || undefined);
    const emit = defineEmits(["update", "next"]);
    const wasSkipped = ref(false)
    const getRadioButtonColor = () => {
        switch (checkbox.value) {
            case 'Yes':
                return '#008000'
            case 'No':
                return '#800000'
            default:
            return
        }
    }
    
    const onValueChange = () => {
        wasSkipped.value = false;
        emit('update')
    }
    const skip = () => {
        wasSkipped.value = true;
        emit('update')
    }
    const load = (value: string | number | boolean | undefined) => {
        checkbox.value = value as 'Yes' | 'No' || undefined;
    }
    const validate = () :  'under' | 'over' | 'invalid' | 'ok' | 'ignore' => {
        if (wasSkipped.value) return "ignore";
        if (checkbox.value == null) return "invalid";
        if (checkbox.value == "No") return "invalid";
        return 'ok';
    }
    const value = () => {
        if (wasSkipped.value) return "Skipped";
        return checkbox.value;
    }
    defineExpose({ validate, load, value, skip });

</script>

<template>
    <el-radio-group
            v-model="checkbox"
            :fill="getRadioButtonColor()"
            size="large"
            style="justify-content: center"
            @change="onValueChange"
      >
        <el-radio-button :value="'Yes'" label="Yes" />
        <el-radio-button :value="'No'" label="No" />
      </el-radio-group>
</template>

<style scoped>

</style>