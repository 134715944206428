<script lang="ts" setup>
import { ref } from 'vue';
const props = defineProps<{
    modelValue: number | string | boolean | undefined | 'Yes' | 'No',
    min: number | undefined,
    max: number | undefined,
    options: string[] | string | undefined
}>();
const val = ref<Date | undefined>(undefined);
const emit = defineEmits(["update", "next"]);
const load = (value: string | number | boolean | undefined) => {  
}
const validate = () :  'under' | 'over' | 'invalid' | 'ok' | 'ignore' | 'error' => {
    return 'ok'
}
const value = () => {
   if (val.value == undefined) return new Date();
   return val.value;
}
defineExpose({ validate, load, value });
</script>

<template>
    <div class="message">
        {{props.options}}
    </div>
</template>

<style scoped>

</style>