<script lang="ts" setup>
    import { ref } from 'vue';
    const props = defineProps<{
        modelValue: number | string | boolean | undefined | 'Yes' | 'No',
        min: number | undefined,
        max: number | undefined,
        options: string[] | string | undefined
    }>();
    const selected = ref<string>(props.modelValue?.toString() || '');
    const emit = defineEmits(["update", "next"]);
    const wasSkipped = ref(false)
    const onValueChange = () => {
        wasSkipped.value = false;
        emit('update')
    }

    const load = (value: string | number | boolean | undefined) => {
        if (value != undefined) {
            selected.value = value?.toString()
        }
    }
    const validate = () :  'under' | 'over' | 'invalid' | 'ok' | 'ignore' => {
        return selected.value != undefined ? "ok" : "invalid";
    }
    const value = () => {
        if (wasSkipped.value) return "Skipped";
        return selected.value;
    }
    const skip = () => {
        wasSkipped.value = true;
    }
    defineExpose({ validate, load, value, skip });
</script>

<template>
    <div
        style="justify-content: center; justify-items: center; display: flex"
      >
        <el-select
          v-model="selected"
          placeholder="Select"
          size="large"
          style="width: 90%"
          @change="onValueChange"
        >
          <el-option
            v-for="(item, index) in props.options"
            :key="index"
            :label="item"
            :value="item"
          />
        </el-select>
    </div>
</template>

<style scoped>

</style>