<script lang="ts" setup>
    import type { SignaturePadRef } from '@/interfaces/SignaturePadRef';
    import { ref, onBeforeMount, onDeactivated, reactive } from 'vue';
    import Vue3Signature from 'vue3-signature';

    const props = defineProps<{
        modelValue: number | string | boolean | undefined | 'Yes' | 'No',
        min: number | undefined,
        max: number | undefined,
        options: string[] | string | undefined
    }>();
    const emit = defineEmits(["update", "next"]);
    const signaturePad = ref<SignaturePadRef>()
    const name = ref<string>('');
    const error = ref<boolean>(false);
    const signaturePadState = reactive({
        count: 0,
        option: {
            penColor: 'rgb(0, 0, 0)',
            backgroundColor: 'rgb(255,255,255)'
        },
        disabled: false
    });

    const saveSignature = () => {
        emit('update');
    }
    const clearSignature =() => signaturePad.value!.clear();

    let int: number = 0;    
    onDeactivated(() => { 
        window.clearInterval(int);
    });
    const load = (value: string | number | boolean | undefined) => {
        int = window.setInterval(() => {
            saveSignature();
        }, 1000);
    }
    const validate = () :  'under' | 'over' | 'invalid' | 'ok' | 'ignore' | 'error' => {
        if (signaturePad.value!.isEmpty()) {
            error.value = true;
            return "error";
        }
        if (name.value == "") {
            error.value = true;
            return "error";
        }
        const img = signaturePad.value!.save();
        const val = name.value + "|" + (!signaturePad.value!.isEmpty() ? img : '')
        return /([^\|]+)\|([^\|]+)/.test(val as string) ? "ok" : "invalid";
    }
    const value = () => {
        const img = signaturePad.value!.save();
        return name.value + "|" + (!signaturePad.value!.isEmpty() ? img : '');
    }
    defineExpose({ validate, load, value });
</script>

<template>
    <div>
        <div style="position:relative; width: 550px; max-width:100%; margin: 0 auto;">
            <div class="signature-pad-container">
                <Vue3Signature
                    ref="signaturePad"
                    :disabled="signaturePadState.disabled"
                    :sigOption="signaturePadState.option"
                ></Vue3Signature>
            </div>
            <div class="eraser">
                <i @click="clearSignature" class="fas fa-eraser"></i>
            </div>
        </div>

        <div class="name-container" style="margin-top: 30px;">
            <label>Your Name:</label>
            <el-input @keyup="saveSignature" v-model="name" placeholder="Enter your name"></el-input>
            <div v-if="error"  style="color: red">Please sign and enter your name</div>
        </div>
    </div>
</template>

<style scoped>
.signature-pad-container {
  border: #7d7d7d 1px solid;
  width: 550px;
  height: 200px;
  margin: 20px auto auto;
  position:relative;
  max-width:80%;
}
.name-container {
    height:100%;
    width: 550px;
    max-width:80%;
    margin: 0 auto;
}
.eraser{
  font-size: 2em;
  position: absolute;
  bottom: -50px;
  right: 30px;
}
</style>