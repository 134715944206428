<script lang="ts" setup>
import { onMounted, ref, inject } from 'vue'
import Slider from '@/components/Slider.vue'
import SliderPage from '@/components/SliderPage.vue'
import { useChecksStore } from '@/stores/checksStore';
import { useSettingsStore } from '@/stores/settingsStore'
import { storeToRefs } from 'pinia'
import type { CheckTask } from '@/interfaces/CheckTask'
import ReportIssueModalComponent from '@/components/ReportIssueModalComponent.vue'
import { ChecksService } from '@/services/ChecksService'
import type { CompletedCheck } from '@/interfaces/CompletedCheck'
import type { CheckAnswer } from '@/interfaces/CheckAnswer'
import type { SaveCompletedCheckResponse } from '@/interfaces/SaveCompletedCheckResponse'
import { ElNotification } from 'element-plus'
import { offlineDatabase } from '@/offlineDatabase'
import { OfflineDatabaseService } from '@/services/OfflineDatabaseService'
import { CheckOfflineService } from '@/services/CheckOfflineService'
import { useRouter } from 'vue-router'
import { CheckStep } from '@/enums/CheckStep'
import { InputControl, InputType, isInputTypeTemperature } from '@/enums/InputType'
import CorrectiveActionsModalComponent from '@/components/CorrectiveActionsModal/CorrectiveActionsModal.vue'
import type { TaskComponent, CorrectiveActionsModalRef } from '@/components/CorrectiveActionsModal/CorrectiveActionsModalRef'
import type { Issue } from '@/interfaces/Issue'

const worker: Worker = inject('worker')!;
const emit = defineEmits(["backToSummary"])
const props = defineProps<{startstepid: string | undefined}>()
const router = useRouter()
const checksStore = useChecksStore()
const settingsStore = useSettingsStore()
const { currentCheckMetadata } = storeToRefs(checksStore)
const offlineDatabaseService = OfflineDatabaseService()
const checkOfflineService = CheckOfflineService()
const checkTasks = ref(currentCheckMetadata.value!.check.checkTasks.sort((a, b) => (a.order > b.order ? 1 : -1)))
const windowWidth = window.innerWidth;
const isReportIssueModalComponentOpen = ref(false)
const reportIssueModalRef = ref(null as any)
const activeIndex = ref(props.startstepid == undefined ? 0 : currentCheckMetadata.value!.check.checkTasks.findIndex(x => x.id == props.startstepid));
const activeTask = ref(currentCheckMetadata.value!.check.checkTasks[activeIndex.value]);
const confirmCancelDialogVisible = ref(false)
const correctiveActionsModalComponentRef = ref<CorrectiveActionsModalRef>()
const TaskRef = ref([] as TaskComponent[])
const loading = ref(false);

const issueAlreadyLogged = (checkid:string) => {
  if (checksStore.currentCheckMetadata != undefined && checksStore.currentCheckMetadata?.check.issues == undefined) 
    checksStore.currentCheckMetadata.check.issues = [];
  return checksStore.currentCheckMetadata?.check.issues.find(x => x.taskId === checkid) != null
}
const openReportIssueModal = (task: CheckTask) => {
  if (reportIssueModalRef.value) {
    const checkId: string = task.checkId
    const taskId: string = task.id
    reportIssueModalRef.value.setCheckId(checkId)
    reportIssueModalRef.value.setTaskId(taskId)
  }
  isReportIssueModalComponentOpen.value = true
}
const setAlarm = () => {
  if (activeTask.value.alarmMinutes > 0) 
    worker.postMessage({ 
      type: 'alarm',
      minutes: activeTask.value.alarmMinutes,
      checkId: activeTask.value.checkId,
      message: `${currentCheckMetadata.value!.check.title} is due`
    });
}
const validateCurrentComponent = () => {
  if(activeTask.value.value == 'Skipped' && activeTask.value.skippable) return true;
  const validation = TaskRef.value[activeIndex.value].validate();
  if (validation == 'invalid' || validation == 'over' || validation == 'under') {
    if (!issueAlreadyLogged(activeTask.value.id)) {
      openCorrectiveActionsModal(validation);
      return false;
    }
  }
  if (validation == 'error') {
    return false;
  }
  return true;
}
const validateAllComponents = () => {
  for (let i = 0; i < TaskRef.value.length; i++) {
    if (checkTasks.value[i].value == 'Skipped' && checkTasks.value[i].skippable) continue;
    const validation = TaskRef.value[i].validate();
    if (validation == 'invalid' || validation == 'over' || validation == 'under' || validation == 'error') {
      if (!issueAlreadyLogged(checkTasks.value[i].id)) 
        return checkTasks.value[i].label;
    }
  }
  return "";
}
const completeCheck = async () => {
  const checksService = new ChecksService()
  const refreshedCheck = await offlineDatabaseService.getCheckByGuid(currentCheckMetadata.value!.guid);
  const completedCheck: CompletedCheck = {
    checkId: currentCheckMetadata.value!.check.id,
    answers: currentCheckMetadata.value!.check.checkTasks.map(
      (task: CheckTask) => ({taskId: task.id, value: task.value ? (task.value as string) : '' }) as CheckAnswer
    ) as CheckAnswer[],
    issues: refreshedCheck!.check.issues,
  }
  let result: SaveCompletedCheckResponse = await checksService.saveCompletedCheck(completedCheck)
  if (result.success) {
    ElNotification({title: 'Success', message: 'Check has been saved', type: 'success', customClass: 'notification' }); 
    if (currentCheckMetadata.value!.check.canBeFinishedMultipleTimes) {
      offlineDatabase.checkOfflineMetadata.where("checkId").equals(currentCheckMetadata.value!.checkId).modify({ 
        "check.isCompleted": true, 
      });
      offlineDatabase.checkOfflineMetadata.where("guid").equals(currentCheckMetadata.value!.guid).delete();
    } else {
      offlineDatabase.checkOfflineMetadata.where("guid").equals(currentCheckMetadata.value!.guid).modify({ 
        "check.isCompleted": true, 
        "currentCheckStep": CheckStep.Initial 
      });
    }
    await router.push('/account/dashboard')
    return;
  } else {
    ElNotification({title: 'Error', message: 'Check could not been saved', type: 'error', customClass: 'notification'})
  }
}
const onNextClick = async () => {
  if (!validateCurrentComponent()) return;
  await onValueChange();
  activeIndex.value += 1;
  activeTask.value = checkTasks.value.at(activeIndex.value)!
  TaskRef.value[activeIndex.value].load(activeTask.value.value); 
  setAlarm();
}
const onFinish = async () => {
  if (!validateCurrentComponent()) return;
  var requiredTask = validateAllComponents();
  if (requiredTask != "") {
    ElNotification({ title: 'Error', message: 'Task '+requiredTask+' is requires a value', type: 'error', customClass: 'notification' })
    currentCheckMetadata.value!.currentCheckStep = CheckStep.TaskContents;
    emit("backToSummary")
    return;
  }
  loading.value = true;
  await completeCheck();  
}
const onPreviousTabClick = async () => {
  activeIndex.value -= 1; 
  activeTask.value = checkTasks.value.at(activeIndex.value)!
  if(activeIndex.value == -1) {
    emit("backToSummary")
  }
}
const onValueChange = async () => {
  console.log(activeTask.value.value, "setting value")
  const newValue: string | number = TaskRef.value[activeIndex.value].value()!;
  activeTask.value.value = newValue;

  await checkOfflineService.updateCheckTaskProgress(
    currentCheckMetadata.value!.guid,
    activeTask.value,
    newValue,
    CheckStep.TasksWizard
  )
}
const openCorrectiveActionsModal = (validationOutcome: 'over' | 'under' | 'invalid') => {
  if (!correctiveActionsModalComponentRef.value) return;

  if (!issueAlreadyLogged(activeTask.value.id)){
    correctiveActionsModalComponentRef.value!.openModal(
      activeTask.value.checkId,
      activeTask.value.id,
      activeTask.value.label,
      activeTask.value.correctiveActions,
      validationOutcome
    )
  }
}
const onCorrectiveActionsConfirmed = (issue: Issue) => {
  checksStore.currentCheckMetadata?.check.issues.push(issue)
  if (activeIndex.value != checkTasks.value.length) {
    onNextClick();
  } else {
    onFinish();
  }
}
const onCancelCheckClicked = (t:boolean) => {
  if (t)
    checkOfflineService.cancelCheck(currentCheckMetadata!.value!)
  router.push('/account/dashboard')
}
const getComponent = (type: InputType) : any => {
  return InputControl[type];
}
const getSettings = (task: CheckTask) => {
  if (isInputTypeTemperature(task.inputType)) {
    if (task.inputType === InputType.CustomTemperature ) {
      return JSON.parse(task.inputTypeValues[0]);
    }
    const settings = settingsStore.getTemperatureSettings(task.inputType)
    return settings;
  }
  return {
    min: 0,
    max: 100
  }
}
const skip = async (taskId: string) => {
  const task = checkTasks.value.find(x => x.id === taskId);
  TaskRef.value[activeIndex.value].skip()
  if (task && task.skippable) {
    task.value = 'Skipped';
    activeTask.value.value = task.value;

    await checkOfflineService.updateCheckTaskProgress(
      currentCheckMetadata.value!.guid,
      activeTask.value,
      task.value,
      CheckStep.TasksWizard
    )
    onNextClick();
  }
}

onMounted(() => {
  activeTask.value = checkTasks.value.at(activeIndex.value)!
  if (TaskRef.value[activeIndex.value])
    TaskRef.value[activeIndex.value].load(activeTask.value.value);
});


</script>

<template>
  <Slider v-model="activeIndex">
    <SliderPage v-for="(task, index) in checkTasks">
      <div style="height: 100%; margin-top: 1em;" :style="{width: windowWidth + 'px'}">
        <div class="pane-content-container">
          <el-text style="margin-bottom: 10px">
            {{ index+1 }} of {{ checkTasks.length - 1 }}
          </el-text>
          <el-text size="large" style="font-weight: bold; color: #151740; text-align: center">
            {{ task.label }}
          </el-text>
          <el-text v-if="task.description" style="margin-top: 10px; display: flex; width: 88%; justify-content: center">
            {{ task.description }}
          </el-text>

          <div class="pane-content">
            <component 
              :is="getComponent(task.inputType)"
              v-model="task.value"
              :id="task.id"
              :min="getSettings(task).min"
              :max="getSettings(task).max"
              :options="task.inputTypeValues"
              ref="TaskRef"
              @update="onValueChange"
              @next="onNextClick"
            ></component>
            <div v-if="task.skippable" class="centered">
              <el-button class="btn-skip" @click="skip(task.id)">Skip this step</el-button> 
            </div>
          </div>
        </div>
      </div>
    </SliderPage>
  </Slider>
    
    <div style="margin-top: auto;" class="buttonSection">
      <div style="display: flex; padding: 0px">     
        <el-button
          class="bottom-buttons previous-button"
          @click="onPreviousTabClick"
          >Previous
        </el-button>
        
        <el-button class="bottom-buttons report-issue-button" @click="openReportIssueModal(activeTask)">
          <i class="fa fa-triangle-exclamation"></i>
        </el-button>
        
        <el-button class="bottom-buttons cancel-button" 
          @click="confirmCancelDialogVisible = true"
          >
          <i class="fa fa-x"></i>
        </el-button>

        <el-button
          v-if="activeIndex < checkTasks.length - 1"
          class="bottom-buttons next-button"
          @click="onNextClick"
          >Next
        </el-button>

        <el-button
          :loading="loading"
          v-if="activeIndex >= checkTasks.length - 1"
          class="bottom-buttons next-button"
          @click="onFinish"
          >Finish
        </el-button>
      </div>
    </div>

  <ReportIssueModalComponent
    ref="reportIssueModalRef"
    :is-open="isReportIssueModalComponentOpen"
    @close="isReportIssueModalComponentOpen = false"
  />

  <CorrectiveActionsModalComponent
    ref="correctiveActionsModalComponentRef"
    @sent="onCorrectiveActionsConfirmed"
  />

  <el-dialog v-model="confirmCancelDialogVisible" title="Leave check" width="500">
    <span>Do you want to Save and leave for later or cancel this check?</span>
    <template #footer>
      <div class="dialog-footer">
        <el-button @click="confirmCancelDialogVisible = false">Back</el-button>
        <el-button type="primary" @click="onCancelCheckClicked(false)">Save for later</el-button>
        <el-button type="danger" @click="onCancelCheckClicked(true)">Cancel and delete</el-button>
      </div>
    </template>
  </el-dialog>
</template>

<style scoped>
.n-config-provider{height: 100%}


.bottom-buttons {
  border-radius: 0;
  height: 75px;
  font-size: 20px;
  margin:0!important;
}

.report-issue-button {
  --el-button-bg-color: #dfae45;
  --el-button-text-color: white;
  --el-button-hover-bg-color: #d38f36;
  --el-button-hover-text-color: white;
  flex-basis: 20%;
}

.cancel-button {
  --el-button-bg-color: #e1341e;
  --el-button-text-color: white;
  --el-button-hover-bg-color: #e1341e;
  --el-button-hover-text-color: white;
  flex-basis: 20%;
}

.next-button {
  --el-button-bg-color: #79b342;
  --el-button-text-color: white;
  --el-button-hover-bg-color: #79b342;
  --el-button-hover-text-color: white;
  flex-basis: 30%;
}

.previous-button {
  --el-button-bg-color: #009de0;
  --el-button-text-color: white;
  --el-button-hover-bg-color: #009de0;
  --el-button-hover-text-color: white;
  flex-basis: 30%;
}

.buttonSection{
  position:fixed;
  bottom:0;
  left:0;
  width:100%;
}

.slider{
  display: flex;
  transition: all .3s cubic-bezier(0.645,0.045,0.355,1);
}

.centered{
  text-align:center;
  margin: 30px;
}

.pane-content-container {
  display: flex;
  flex-direction: column;
  width: 90%;
  height: 100%;
  margin: 0 auto;
}

.pane-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  margin-top: 10px;
}

.bottom-buttons {
  border-radius: 0;
  height: 75px;
  font-size: 20px;
  margin:0!important;
}

.report-issue-button {
  --el-button-bg-color: #dfae45;
  --el-button-text-color: white;
  --el-button-hover-bg-color: #d38f36;
  --el-button-hover-text-color: white;
  flex-basis: 20%;
}

.cancel-button {
  --el-button-bg-color: #e1341e;
  --el-button-text-color: white;
  --el-button-hover-bg-color: #e1341e;
  --el-button-hover-text-color: white;
  flex-basis: 20%;
}

.next-button {
  --el-button-bg-color: #79b342;
  --el-button-text-color: white;
  --el-button-hover-bg-color: #79b342;
  --el-button-hover-text-color: white;
  flex-basis: 30%;
}

.previous-button {
  --el-button-bg-color: #009de0;
  --el-button-text-color: white;
  --el-button-hover-bg-color: #009de0;
  --el-button-hover-text-color: white;
  flex-basis: 30%;
}

.buttonSection{
  position:fixed;
  bottom:0;
  left:0;
  width:100%;
}

.btn-skip{
  font-size: 1.5em;
  margin-top: 10px;
}
</style>