export const enum InputType {
  Text,
  TextArea,
  CheckBox,
  FridgeTemp,
  FreezerTemp,
  HotHoldingTemp,
  CookTemp,
  Signature,
  Select,
  Message,
  Timestamp,
  CoolingTemp,
  HotProbeTemp,
  ColdProbeTemp,
  ColdHoldingTemp,
  RegenerationTemp,
  CheckBoxNoExpected,
  CustomTemperature
}

import MessageComponent from '@/components/tasks/MessageComponent.vue'
import SignatureComponent from '@/components/tasks/SignatureComponent.vue'
import TemperatureComponent from '@/components/tasks/TemperatureComponent.vue'
import TextareaComponent from '@/components/tasks/TextareaComponent.vue'
import TextComponent from '@/components/tasks/TextComponent.vue'
import CheckboxComponent from '@/components/tasks/CheckboxComponent.vue'
import CheckboxNoExpectedComponent from '@/components/tasks/CheckboxNoExpectedComponent.vue'
import DropdownComponent from '@/components/tasks/DropdownComponent.vue'
import TimestampComponent from '@/components/tasks/TimestampComponent.vue'
import type { Component } from 'vue'
export const InputControl: Component[] = [
  TextComponent,
  TextareaComponent,
  CheckboxComponent,
  TemperatureComponent,
  TemperatureComponent,
  TemperatureComponent,
  TemperatureComponent,
  SignatureComponent,
  DropdownComponent,
  MessageComponent,
  TimestampComponent,
  TemperatureComponent,
  TemperatureComponent,
  TemperatureComponent,
  TemperatureComponent,
  TemperatureComponent,
  CheckboxNoExpectedComponent,
  TemperatureComponent,
]

export const isInputTypeTemperature = (inputType: InputType) => {
  return (
    inputType === InputType.FreezerTemp ||
    inputType === InputType.FridgeTemp ||
    inputType === InputType.CookTemp ||
    inputType === InputType.HotHoldingTemp ||
    inputType === InputType.ColdHoldingTemp ||
    inputType === InputType.CoolingTemp ||
    inputType === InputType.HotProbeTemp ||
    inputType === InputType.ColdProbeTemp ||
    inputType === InputType.RegenerationTemp ||
    inputType === InputType.CustomTemperature
  )
}
