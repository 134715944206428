<script lang="ts" setup>
    import { ref } from 'vue';

    const props = defineProps<{
        modelValue: number | string | boolean | undefined | 'Yes' | 'No',
        min: number | undefined,
        max: number | undefined,
        options: string[] | string | undefined
    }>();
    const error = ref<boolean>(false);
    const text = ref<string>(props.modelValue?.toString() || "");
    const emit = defineEmits(["update", "next"]);
    const wasSkipped = ref(false);
    const onValueChange = () => {
        emit('update')
    }
    const skip = () => {
        wasSkipped.value = true;
    }

    const load = (value: string | number | boolean | undefined) => {
        text.value = value?.toString() || "";
    }
    const validate = () :  'under' | 'over' | 'invalid' | 'ok' | 'ignore' => {
        if (wasSkipped.value) return "ignore";  
        return text.value != "" ? "ok" : "invalid";
    }
    const value = () => {
        if (wasSkipped.value) return "Skipped";
        return text.value;
    }
    defineExpose({ validate, load, value, skip });
</script>

<template>
    <div class="text-container">
        <el-input
            v-model="text"
            rows="8"
            style="font-size: 2em"
            type="textarea"
            @keyup="onValueChange"
        />
        <span v-if="error" style="color: red">Please enter details</span>
    </div>
</template>

<style scoped>
 .text-container{
        width:550px;
        max-width:80%;
        margin: 0 auto;
    }
</style>